import React from "react" 
import ProgramSlotRow from "../program-slot-row"

export default function Weekday({ day, slots, activeDay = 0 }) {
  const actualSlots = slots.filter(item => item.node.weekday_start === day) 
  return (
    <div className={`w-full ${day !== activeDay && "hidden"}`}>
      {actualSlots &&
        actualSlots.map((slot, i) => (
          <ProgramSlotRow
            key={`program${day}${i}`}
            slot={slot.node}
            program={slot.node.program}
          />
        ))}
    </div>
  )
}
