import React from "react"
import { Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Placeholder from "../images/show-placeholder.png"
import { Pad, returnWeekday, secondsToTime } from "../utils/functions"

export default function ProgramRow({ program }) {
  // console.log(post)
  const image = getImage(program.featuredImg)
  const title = program.name || program.id
  return (
    <>
      <Link to={`/${program.slug}`} itemProp="url">
        <article
          className="post-list-item md:flex-row-reverse md:flex gap-4 mb-8 bg-white  p-4 w-full "
          itemScope
          itemType="http://schema.org/Article"
        >
          <div
            className={`md:w-1/5 lg:1/4 mb-4 md:mb-0 ${
              !program.featuredImg ? "hidden md:flex" : ""
            }`}
          >
            {program.featuredImg ? (
              <GatsbyImage className={'w-100 h-auto'} image={image} alt={title} />
            ) : (
              <img src={Placeholder} alt={title} />
            )}
          </div>
          <div className={"md:w-4/5 lg:3/4"}>
            <header className={"block"}>
              <h2 className={"text-4xl mb-4 font-bold"}>
                <span itemProp="headline">{title}</span>
              </h2>
            </header>

            <div className="timetable">
              <p itemProp="description" className="mb-2">
                {program.introduction}
              </p>
              {program.presenter_string && (
                <p itemProp="description" className="mb-2">
                  <span className="font-bold">PRESENTED BY:</span>{" "}
                  {program.presenter_string && program.presenter_string}
                </p>
              )}
              {program.genre_string && (
                <p itemProp="description" className="mb-2">
                  <span className="font-bold">GENRE:</span>{" "}
                  {program.genre_string && program.genre_string}
                </p>
              )}
              {program.slots && (
                <p itemProp="description" className="mb-2">
                  <span className="font-bold">TIMETABLE:</span>{" "}
                </p>
              )}
              {program.slots &&
                program.slots.map((slot, i) => (
                  <div key={`${program.id}${i}`} className={"text-sm"}>
                    <p>
                      {returnWeekday(slot.weekday_start)}{" "}
                      {Pad(
                        slot.hour_start > 12
                          ? slot.hour_start - 12
                          : slot.hour_start
                      )}
                      :{Pad(slot.minute_start)}
                      {slot.hour_start >= 12 ? "PM" : "AM"}
                      {" - "}
                      {Pad(
                        slot.hour_end > 12 ? slot.hour_end - 12 : slot.hour_end
                      )}
                      :{Pad(slot.minute_end)}
                      {slot.hour_end >= 12 ? "PM" : "AM"}{" "}
                    </p>
                    <p></p>
                  </div>
                ))}
            </div>
          </div>
        </article>{" "}
      </Link>
    </>
  )
}
