import React from "react"
import { Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Placeholder from "../images/show-placeholder.png"
import { Pad, convertUTCToTimezone } from "../utils/functions"
import ProgramEpisodeItem from "./program/episode-item"
import { StationContext } from "../context/station-context"
import moment from "moment"
import "moment-timezone"

export default function ProgramSlotRow({ program, slot }) {
  // console.log(post)
  const { station } = React.useContext(StationContext)
  const image = getImage(slot.featuredImg)
  const title = program.name || program.id
  const [actualEpisodes, setActualEpisodes] = React.useState([])

  React.useEffect(() => {
    const newEpisodes = slot.episodes.filter(item => {
      const startStream = convertUTCToTimezone(
        item.end_start,
        null,
        station.timezone,
        ""
      )
      if (moment().isAfter(startStream)) {
        return item
      }
    })
    setActualEpisodes(newEpisodes)
  }, [slot, setActualEpisodes])

  return (
    <>
      <article
        className="post-list-item md:grid md:grid gap-4 mb-8 bg-white gap-4 grid-cols-12 p-4 w-full "
        itemScope
        itemType="http://schema.org/Article"
      >
        <div className={"col-span-12 lg:col-span-2"}>
          {Pad(
            slot.hour_start > 12
              ? slot.hour_start - 12
              : slot.hour_start === 0
              ? 12
              : slot.hour_start
          )}
          :{Pad(slot.minute_start)}
          {slot.hour_start >= 12 ? "PM" : "AM"}
        </div>
        <div className={"col-span-12 lg:col-span-7"}>
          <header className={"block"}>
            <Link to={`/${program.slug}`} itemProp="url">
              <h2 className={"text-4xl mb-4 font-bold"}>
                <span itemProp="headline">{title}</span>
              </h2>
            </Link>
          </header>

          <div className="timetable">
            <p itemProp="description" className="mb-2">
              {program.introduction}
            </p>
            {program.presenter_string && (
              <p itemProp="description" className="mb-2">
                <span className="font-bold">PRESENTED BY:</span>{" "}
                {program.presenter_string && program.presenter_string}
              </p>
            )}
            {program.genre_string && (
              <p itemProp="description" className="mb-2">
                <span className="font-bold">GENRE:</span>{" "}
                {program.genre_string && program.genre_string}
              </p>
            )}
          </div>
          {actualEpisodes && actualEpisodes.length > 0 && (
            <div>
              <p className="mt-4 mb-2 font-semibold">Latest Episode:</p>
              <ProgramEpisodeItem
                index={1}
                episode={{
                  node:  { ...actualEpisodes[0], program: program } ,
                }}
              />
            </div>
          )}
        </div>
        <div
          className={`col-span-12 lg:col-span-3 ${
            !program.featuredImg ? "hidden md:flex" : ""
          }`}
        >
          {slot.featuredImg ? (
            <GatsbyImage className={"w-100 h-auto"} image={image} alt={title} />
          ) : (
            <img src={Placeholder} alt={title} />
          )}
        </div>
      </article>
    </>
  )
}
