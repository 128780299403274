import React, { useState } from "react"
import { graphql } from "gatsby"
import ProgramRow from "../components/program-row"
import { WeekDays } from "../utils/functions"
import Weekday from "../components/schedule/weekday"

import Layout from "../components/layout"
import Seo from "../components/seo"

const ScheduleIndex = ({ data, location }) => {
  const [day, setDay] = useState(0)
  const siteTitle = data.site.siteMetadata?.title || `Title`
  const slots = data.allSlot.edges

  return (
    <Layout location={location} title={siteTitle}>
      <Seo title="All posts" />
      <div className="w-full grid grid-cols-12">
        <div className="col-span-12 lg:col-span-3">
          <div className="w-full block">
            {WeekDays.map((weekDay, i) => (
              <button
                key={`button${i}`}
                onClick={() => setDay(i)}
                className={`w-full  mb-2 font-bold py-2 px-4 rounded uppercase ${
                  day === i
                    ? "w-full text-white bg-brand hover:bg-band hover:text-white"
                    : "text-brand bg-white hover:bg-brand hover:text-white"
                }`}
              >
                {weekDay}
              </button>
            ))}
          </div>
        </div>
        <div className="col-span-12 lg:col-span-9">
          {WeekDays.map((d, i) => (
            <Weekday slots={slots} day={i} key={`slot${d}`} activeDay={day} />
          ))}
        </div>
      </div>
    </Layout>
  )
}

export default ScheduleIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      nodes {
        excerpt
        fields {
          slug
        }
        frontmatter {
          date(formatString: "MMMM DD, YYYY")
          title
          description
        }
      }
    }
    allSlot {
      edges {
        node {
          ...Slot
          program {
            ...Program
          }
          episodes {
            id
            original_id: id
            updated_at
            station_id
            slot_id
            show_start_date
            show_start
            show_end_date
            show_end
            program_id
            created_at
            duration
          }
        }
      }
    }
  }
`
