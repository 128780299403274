import React from "react"
import { Link } from "gatsby"
import {
  PlayIcon,
  PauseIcon,
  PlusIcon,
  CheckIcon,
  EyeIcon,
} from "@heroicons/react/solid"
// import AudioPlayer from "./audio-player"
import { StationContext } from "../../context/station-context"
import moment from "moment"
import "moment-timezone"
import { convertUTCToTimezone } from "../../utils/functions"

export default function ProgramEpisodeItem({
  episode,
  index,
  showPlayer = true,
}) {
  const {
    setIsPlaying,
    currentTrack,
    setCurrentTrack,
    addTrackToPlaylist,
    isPlaying,
    playlist,
    station,
  } = React.useContext(StationContext)
  const [inPlayList, setInPlayList] = React.useState(false)

  const start = convertUTCToTimezone(
    episode.node.show_start,
    null,
    station.timezone
  )
  const startStream = convertUTCToTimezone(
    episode.node.show_start,
    null,
    station.timezone,
    ""
  )
  React.useEffect(() => { 
    const test = playlist.filter(item => item.node.original_id === episode.node.original_id)
    if (test.length > 0) {
      setInPlayList(true)
    } else {
      setInPlayList(false)
    }
  }, [playlist])

  // console.log("Here", episode)
  const streamUrl = `https://hls-server.nicklarosa.net/public/endpoints/ondemand/duration/${station.hls_stream}/aac_96/${startStream}/${episode.node.duration}/playlist.m3u8?unique=website`
  return moment().isAfter(startStream) && episode.node.timestamp >= parseInt(process.env.GATSBY_EPISODE_START) ? (
    <article
      className="episode-list-item grid gap-4 mb-2 md:mb-4 md:pb-8 w-full grid-cols-6 border-b-3 border-black"
      itemScope
      itemType="http://schema.org/Article"
    >
      <header className={"col-span-5"}>
        <p className={"text-sm font-semibold text-gray-900"}>{start}</p>

        {showPlayer && moment().isAfter(startStream) && station.hls_stream ? (
          <div className={"w-full mt-4 flex gap-2"}>
            <button
              onClick={() => {
                if (
                  isPlaying &&
                  currentTrack &&
                  currentTrack.node.original_id === episode.node.original_id
                ) {
                  setIsPlaying(!isPlaying)
                } else {
                  setCurrentTrack({
                    ...episode,
                    url: streamUrl,
                    start: start,
                    isLive: false,
                  })
                }
              }}
            >
              {isPlaying === true &&
              currentTrack &&
              currentTrack.node.original_id === episode.node.id ? (
                <PauseIcon className={"w-8 h-8  text-blue-500 "} />
              ) : (
                <PlayIcon className={"w-8 h-8  text-brand "} />
              )}
            </button>
            <button
              onClick={() => {
                if (inPlayList === false) {
                  addTrackToPlaylist({
                    ...episode,
                    url: streamUrl,
                    start: start,
                    isLive: false,
                  })
                }
              }}
            >
              {inPlayList === true ? (
                <CheckIcon className={"w-8 h-8 "} />
              ) : (
                <PlusIcon className={"w-8 h-8 text-gray-900"} />
              )}
            </button>
          </div>
        ) : null}
      </header>
    </article>
  ) : null
}
